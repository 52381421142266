.scrollbar {
    display: none;    
    height: 100vh;
    position: fixed;
    right: 12px;
    
    &-sub {
        height: 50%;
        margin: auto;
        display: flex;
        flex-direction: column;    
    }
}

.dot {
    height: 30px;
    width: 30px;
    background-color:rgb(112,112,112);
    border-radius: 50%;
    margin: auto;
    box-shadow: 0 0 0 3px rgb(96,96,96);
    transition: 0.5s;
    
    &:focus {
        outline: 0px;
    }
}
    

.dot-2 { 
    height: 50px;
    width: 50px;
    background-color:rgb(184,184,184);
    box-shadow: 0 0 0 3px rgb(224,224,224);
    border-radius: 50%;
    margin: auto;
    transition: 0.5s;
    
    &:focus {
        outline: 0px;
    }
}

@media only screen and (min-width: 950px) {
    
    .scrollbar {
        display: flex;
    }
}

@media only screen and (min-width: 1240px) {
    
    .scrollbar {
        right: 30px
    }
}