.skills-container {
    
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 0;
    margin: auto;
    
    
    &-sub {
        display: flex;
        justify-content: center;
        align-content: center;
        margin: 0 0;
        padding-left: 20px;
        max-width: 900px;
            
        
        
    }
}