.projects-container {    
    
    margin: auto;
    padding: 0;
    
    img {
        width: 75%;
        height: auto;
        margin: auto;
        
    }    
}



